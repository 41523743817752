import { useParams, useNavigate } from 'react-router-dom'
import { Mission } from '../interfaces/missions.interface'
import { useEffect, useState } from 'react'
import missionService from '../services/missions.service'
import { Backdrop, Card, Chip, CircularProgress, Typography, Button } from '@mui/material'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import MissionCard from './mission-card.component'

const MissionDetails: React.FC<any> = () => {
    const { id } = useParams()
    const missionId = id
    const [mission, setMission] = useState<Mission | null>(null)
    const [loading, setLoading] = useState(false)

    const btRetourStyle = {
        background: 'none',
        border: 'none',
        color: 'gray',
        fontSize: '16px',
        textDecoration: 'None',
        cursor: 'pointer',
        padding: '10px',
        fontWeight: 'bold'
      };

    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate('/mission')
    }

    useEffect(() => {
        if (missionId) {
            setLoading(true)
            missionService
                .getOne(missionId)
                .then(res => setMission(res.data.data))
                .catch(console.log)
                .finally(() => setLoading(false))
        }
        //eslint-disable-next-line
    }, [])

    const getReferenceByIdString = (id: string) => {
        if (!id) {
            return ''
        }
        return id.substring(id.length - 5, id.length).toUpperCase()
    }
    const indispens_bonasavoir_style = {
        backgroundColor: '#B9EED5',
        borderRadius: '10px'
      };

    return (
        <div className="p-5">
            {!loading && (
                <>
                    <div className="w-full grid grid-cols-2 gap-5 py-4">
                        <Card className="w-full p-5">
                            <Typography fontWeight="bold">
                                Préstataires <span className="text-slate-400"> {mission && mission.prestataires.length} / {mission && mission.nombrePresta}</span>
                            </Typography>
                            {mission &&
                                mission.prestataires.length > 0 &&
                                mission.prestataires.map((prestataire, i) => (
                                    <>
                                        <Typography className="flex flex-row items-center gap-4 py-4">
                                            <div>
                                                <PersonOutlineRoundedIcon /> {prestataire.firstname} {prestataire.lastname}
                                            </div>
                                            <div>
                                                <StarRoundedIcon style={{ color: BASE_COLOR.PRIMARY }} />
                                                <span className="text-slate-400">4.9</span>
                                            </div>
                                            <div>{`N° HWK${getReferenceByIdString(prestataire._id)}`}</div>
                                        </Typography>
                                        {i === mission.prestataires.length - 1 && <hr />}
                                    </>
                                ))}
                            <Typography fontWeight="bold">Créateur</Typography>
                            <Typography className="flex flex-row items-center gap-4 py-4">
                                <div>
                                    <PersonOutlineRoundedIcon /> {mission?.idCreateur.firstname ?? ''} {mission?.idCreateur.lastname ?? ''}
                                </div>
                                <div>
                                    <StarRoundedIcon style={{ color: BASE_COLOR.PRIMARY }} /> <span className="text-slate-400">4.9</span>
                                </div>
                                <div>
                                {`N° HWK${getReferenceByIdString(mission?.idCreateur?._id ?? '') }`}
                                </div>
                            </Typography>
                        </Card>
                        {mission && <MissionCard mission={mission} title={'Informations'} noNavigation />}
                    </div>
                    <div className="grid grid-cols-3 gap-4 py-5">
                        <div>
                            {mission && mission.epi && (
                                <Card className="p-5">
                                    <Typography fontWeight="bold" className="pb-3">
                                        Les indispensables
                                    </Typography>
                                    <Chip label={mission.epi}/>
                                </Card>
                            )}
                        </div>
                        <Card className="p-5">
                            <Typography fontWeight="bold" className="pb-3">
                                Bon à savoir
                            </Typography>
                            <div className="flex gap-3" >
                                {mission?.isExterieur === true && <Chip label="En extérieur" />}
                                {mission?.missionNuit === true && <Chip label="Mission de nuit" />}
                                {mission?.isEquipe === true && <Chip label="En équipe" />}
                                {mission?.priorite === true && <Chip label="Priorité" />}
                            </div>
                        </Card>
                        <Card className="p-5">
                            <Typography fontWeight="bold" className="pb-3">
                                Coût total prévu
                            </Typography>
                            <Typography fontWeight="bold" variant="h4">
                                {mission?.cout ?? 0} €
                            </Typography>
                        </Card>
                    </div>
                </>
            )}
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
            <Button 
                            // variant="contained" 
                            // color="primary" 
                            style={btRetourStyle}
                            onClick={handleNavigate} 
                            className="mt-4"
                        >
                            🡄Retour
                        </Button>
        </div>
    )
}

export default MissionDetails
