import { BrowserRouter, Routes, Route,  useNavigate } from 'react-router-dom'
import ProtectedRoute from './protectedRoutes'
import AuthContainer from '../pages/Auth/containers/auth.container'
import PrestataireListContainer from '../pages/Utilisateur/containers/utilisateur-prestataire.container'
import UtilisateurCreateurComponent from '../pages/Utilisateur/components/utilisateur-createur.component'
import UtilisateursRolesComponent from '../pages/Utilisateur/components/utilisateur-roles.component'
import { useSelector } from 'react-redux'
import { selectAuth, selectTokenInfo } from '../pages/Auth/store/auth.selector'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ResetPasswordComponent from '../pages/Auth/components/auth-reset-password.component'
import NewPasswordComponent from '../pages/Auth/components/auth-new-password.component'
import PagenotFoundComponent from '../shared/components/PageNotFound/page-not-found.component'
import EditPasswordComponent from '../pages/EditPassword/components/edit-password.component'
import UtilisateurDetailsComponent from '../pages/Utilisateur/components/utilisateur-details'
import DomaineContainer from '../pages/Domaine/containers/domaine.container'
import UtilisateurCreateurMultiComponent from '../pages/Utilisateur/components/utilisateur-createur-multi-compte.component'
import MissionsListComponent from '../pages/Missions/components/missions-list.component'
import MissionDetails from '../pages/Missions/components/mission-details.component'
import PostComponent from '../pages/Post/components/post.component'
import FeesComponent from '../pages/Fees/components/fees.component'
import FeesServicesComponent from '../pages/Fees/components/fees-services.component'
import NotificationComponent from '../pages/Notification/components/notification.component'
import NotificationDetails from '../pages/Notification/components/notification-details.component'
import PaymentsComponent from '../pages/Payments/components/Payments'
import PaymentDetailsComponent from '../pages/Payments/components/payment-details.component'
import AccountBlockingComponent from '../pages/AccountBlocking/components/account-blocking.component'
import CounterDisplayComponent from '../pages/GeneralCounter/components/counter-display.component'
import StatistiquesComponents from '../pages/Statistiques/components/statistiques.component'
import UtilisateurAdminComponent from '../pages/Utilisateur/components/utilisateur-admins.components'
import AboutComponent from '../pages/About/AboutComponent'
import CGVComponent from '../pages/CGV/CGVComponent'
import PageimageComponent from '../pages/Page/components/pageimage.component'
import PageNotFoundComponent from '../pages/Page/components/pagenotfound.component'
import React from 'react'
import Politique from '../pages/Page/components/politiquedeconfodentialite'
import ConditionGenerale from '../pages/Page/components/conditiongenerale'
import PolitiqueComponent from '../pages/Politique/Politique'
import FaqComponent from '../pages/Faq/Faq'
import Message from '../pages/Messages/containers/message.container'
import MinuteComponent from '../pages/minuteur/minuteur.component'
// import Header from '../pages/NavBar/components/header'
// import Footer from '../pages/NavBar/components/Footer'

import UtilisateurASupprimerComponent from '../pages/Utilisateur/components/utilisateur-a-supprimer.component'

const AppRouter = () => {
    const tokenInfo = useSelector(selectTokenInfo)
    const user = useSelector(selectAuth)
    return (
        <>
        {/* {
            !tokenInfo && !user && <Header />
        } */}
            <BrowserRouter>
                <Routes>
                    <Route path="/2420@dminhOw&nkd€v" element={<AuthContainer />} />
                    <Route path="/mot-de-passe-oublier" element={<ResetPasswordComponent />} />
                    <Route path="/noveau-mot-de-passe" element={<NewPasswordComponent />} />
                    <Route path="/" element={<PageimageComponent />} />
                    <Route path="*" element={<PagenotFoundComponent />} />
                    <Route path='/politique' element={<Politique/>} />
                    <Route path='/condition' element={<ConditionGenerale/>} />

                    <Route path="/moncomptehowonk/supprimer" element={<UtilisateurASupprimerComponent />} />

                    <Route element={<ProtectedRoute isAllowed={tokenInfo && user} />}>
                        {/* <Route path="/" element={<DashboardContainer />} /> */}
                        <Route path="/statistiques" element={<StatistiquesComponents />} />
                        {/* <Route path="/" element={<StatistiquesComponents />} /> */}
                        <Route path="/utilisateur/prestataire" element={<PrestataireListContainer />} />
                        <Route path="/utilisateur/prestataire/:id" element={<UtilisateurDetailsComponent />} />
                        <Route path="/utilisateur/createur" element={<UtilisateurCreateurComponent />} />
                        <Route path="/utilisateur/createur/:id" element={<UtilisateurDetailsComponent />} />
                        <Route path="/utilisateur/createur-multi-compte" element={<UtilisateurCreateurMultiComponent />} />
                        <Route path="/utilisateur/createur-multi-compte/:id" element={<UtilisateurDetailsComponent />} />
                        <Route
                            path="/utilisateur/createur-multi-compte/sous-compte/:idParent/:idSousCompte"
                            element={<UtilisateurDetailsComponent />}
                        />
                        
                        <Route path="/utilisateur/roles" element={<UtilisateursRolesComponent />} />
                        <Route path="/utilisateur/admins" element={<UtilisateurAdminComponent />} />
                        <Route path="/mission" element={<MissionsListComponent />} />
                        <Route path="/mission/:id" element={<MissionDetails />} />
                        <Route path="/account-blocking" element={<AccountBlockingComponent />} />
                        <Route path="/notification" element={<NotificationComponent />} />
                        <Route path="/notification/:id" element={<NotificationDetails />} />
                        <Route path="/payments" element={<PaymentsComponent />} />
                        <Route path="/payments/:id" element={<PaymentDetailsComponent />} />
                        <Route path="/details-payments" element={<PaymentDetailsComponent />} />
                        <Route path="/general-counter" element={<CounterDisplayComponent />} />
                        <Route path="/about" element={<AboutComponent />} />
                        <Route path="/parametre/domaine" element={<DomaineContainer />} />
                        <Route path="/parametre/posts" element={<PostComponent />} />
                        <Route path="/parametre/fees" element={<FeesComponent />} />
                        <Route path="/parametre/feesServices" element={<FeesServicesComponent />} />
                        <Route path="/parametre/minuteur" element={<MinuteComponent />} />
                        <Route path="/modification-mot-de-passe" element={<EditPasswordComponent />} />
                        <Route path="/parametre/cgv" element={<CGVComponent />} />
                        <Route path="/parametre/politique" element={<PolitiqueComponent />} />
                        <Route path="/parametre/faq" element={<FaqComponent />} />
                        <Route path="/message" element={<Message />} />
                        <Route path="*" element={<PagenotFoundComponent />} />
                         
                      
                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer position={'bottom-right'} />
            {/* {
            !tokenInfo && !user && <Footer/>
        } */}
        </>
    )
}

export default AppRouter
