import httpCommon from "../../shared/services/http-common";
import { IMess } from "./message.util";

class MessageService {
    async getMessageID(id:string) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/notifier/${id}`)
    }
    async getMessage() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/listMessageAdmin`)
    }
    async deleteMessage(id:string) {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/deleteMessage/${id}`)
    }

    async getMessagePagination(page: number, limit: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/listMessageAdminPagination/${page}/${limit}`)
    }
    async CreateMesage(data:IMess) {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/messageAdmin/create`, data)
    }
}
const messService = new MessageService()
export default messService