export function DateFormater(dateString: string, withoutHours?: boolean): string {
    console.log('dateString')
    console.log(dateString)
    const date = new Date(dateString)

    const day = date.getUTCDate()
    const month = date.getUTCMonth() + 1 // Ajoute 1 car les mois sont indexés à partir de 0
    const year = date.getUTCFullYear()

    const formattedDay = day < 10 ? `0${day}` : `${day}`
    const formattedMonth = month < 10 ? `0${month}` : `${month}`

    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

    let formattedDate = `${formattedDay}/${formattedMonth}/${year}`
    if (withoutHours !== true) formattedDate += ` à ${formattedHours}:${formattedMinutes}`
    return formattedDate
}

export function DateFormaterSans(dateString: string, withoutHours?: boolean): string {
    console.log('dateString')
    console.log(dateString)
    const date = new Date(dateString)

    const day = date.getUTCDate()
    const month = date.getUTCMonth() + 1 // Ajoute 1 car les mois sont indexés à partir de 0
    const year = date.getUTCFullYear()

    const formattedDay = day < 10 ? `0${day}` : `${day}`
    const formattedMonth = month < 10 ? `0${month}` : `${month}`

    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

    let formattedDate = `${formattedDay}/${formattedMonth}/${year}`
    // if (withoutHours !== true) formattedDate += ` à ${formattedHours}:${formattedMinutes}`
    return formattedDate
}
