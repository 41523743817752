import httpCommon from '../../../shared/services/http-common'
import { HourlyCost } from '../interfaces/fees-services.interfaces'

class FeesServicesServices {
    getFees() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/feesServices`)
    }

    updateFees(value: number) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/feesServices/update`, { value })
    }

    getHourlyCost() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/coutHoraire`)
    }

    updateHourlyCost(value: HourlyCost) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/coutHoraire`, { newData: value })
    }
}

const feesServicesServices = new FeesServicesServices()
export default feesServicesServices
