import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
    Button
} from '@mui/material'
import dayjs from 'dayjs';
import { DayDispo } from '../../../shared/constants/Days'
// import DeleteIcon from '@mui/icons-material/Delete'
// import VisibilityIcon from '@mui/icons-material/Visibility'
// import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import { User, UserModel } from '../interfaces/utilisateur.interface'
import { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import userServices from '../services/utilisateur.service'

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//         backgroundColor: BASE_COLOR.WHITE,
//         border: 0
//     },
//     '&:last-child td, &:last-child th': {
//         border: 0
//     },
//     [`& .${tableCellClasses.root}`]: {
//         borderBottom: 'none'
//     }
// }))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        // fontSize: 15,
        fontWeight: 'bold'
        // textTransform: 'uppercase'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const UtilisateurDetailsComponent: React.FC<any> = () => {
    const location = useLocation()
    const { id } = useParams()
    const currentPath = location.pathname
    const isSousCompte = new RegExp('utilisateur/createur-multi-compte/sous-compte').test(currentPath)
    const isPrestataire = new RegExp('utilisateur/prestataire', 'i').test(currentPath)
    const isCreateurNotMulti = new RegExp('utilisateur/createur/', 'i').test(currentPath)
    const [user, setUser] = useState<UserModel | null>(null)

    // const [page, setPage] = useState(0)
    // const [rowsPerPage, setRowsPerPage] = useState(5)
    // const navigate = useNavigate()
    const btRetourStyle = {
        background: 'none',
        border: 'none',
        color: 'gray',
        fontSize: '16px',
        textDecoration: 'None',
        cursor: 'pointer',
        padding: '10px',
        fontWeight: 'bold'
      };

    const [itemParPage, setItemParPage] = useState('10')
    const navigate = useNavigate()
    const handleNavigatePresta = () => {
        navigate('/utilisateur/prestataire')
    }
    const handleNavigateCrea = () => {
        navigate('/utilisateur/createur')
    }
    const handleNavigateMultiCrea = () => {
        navigate('/utilisateur/createur-multi-compte')
    }

    const handleChange = (event: SelectChangeEvent) => {
        setItemParPage(event.target.value)
    }

    useEffect(() => {
        if (id) {
            userServices
                .getById(id)
                .then(res => {
                    setUser(res.data.data as UserModel)
                })
                .catch(console.log)
        }
        //eslint-disable-next-line
    }, [])

    // const defaulProps = {
    //     options: [],
    //     getOptionLabel: (option: any) => option.title
    // }
    // const handleChangePage = (event: any, newPage: any) => {
    //     setPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event: any) => {
    //     setRowsPerPage(parseInt(event.target.value, 10))
    //     setPage(0)
    // }

    // const labelDisplayedRows = ({ from, to, count, page }: any) => {
    //     return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`
    // }

    // const [open, setOpen] = useState(false)
    // const [selectedItem, setSelectedItem] = useState(0)

    // const deleteSousCompte = (idUser: number) => {}
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card className="w-full" sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h6" component="div" fontWeight={'bold'}>
                                Info Utilisateurs
                            </Typography>
                            <Grid container spacing={2}>
                                    <Grid item xs={6}>
                            <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                Nom :{' '}
                                <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                    {user?.lastname}
                                </Typography>
                            </Typography>
                            <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                Prénom :{' '}
                                <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                    {user?.firstname}
                                </Typography>
                            </Typography>
                            <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                Téléphone :{' '}
                                <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                    {user?.numero}
                                </Typography>
                            </Typography>
                            <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                Email :{' '}
                                <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                    {user?.email}
                                </Typography>
                            </Typography>
                            <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                Niveau :{' '}
                                <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                    {user?.statusUser && user?.statusUser}
                                </Typography>
                            </Typography>
                            <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                Date d'inscription :{' '}
                                <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                    {user?.createdAt ? dayjs(user.createdAt).format('DD/MM/YYYY') : ''}
                                </Typography>
                            </Typography>
                            {!user?.statusUser.toLowerCase().includes('crea') && !user?.statusUser.toLowerCase().includes('créa') && (
                                <div>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Ville :{' '}
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {user?.localisation[0]?.address ? user?.localisation[0]?.address : " "}
                                    </Typography>
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Distance :{' '}
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {user?.trajet && user?.trajet.length === 2 ? user?.trajet[0] + ' à ' + user?.trajet[1] : ''}
                                    </Typography>
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Ville à éviter :{' '}
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {user?.villeEviter && user?.villeEviter.map((el: any) => 
                                            <div>{el.address}</div>
                                        )}
                                    </Typography>
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Disponibilités :{' '}
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {user?.disponibility &&
                                            user?.disponibility.length > 0 &&
                                            user?.disponibility.map((elt, i) => {
                                                return (
                                                    <div className="whitespace-nowrap">
                                                        {`- ${elt.label} : ${DayDispo(elt.day)}${
                                                            i !== user?.disponibility.length - 1 ? ' ;' : '.'
                                                        }`}
                                                    </div>
                                                )
                                            })}
                                    </Typography>
                                </Typography>
                                </div>
                            ) }
                            </Grid>
                                <Grid item xs={6}>
                                {!user?.statusUser.toLowerCase().includes('crea') && !user?.statusUser.toLowerCase().includes('créa') && (
                                    <div>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Type de mission :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user?.typeMission && user?.typeMission.map(type_mission => type_mission + '; ')}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Domaines à éviter :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user?.disponibility ? user?.domaineEviter.map(elt => elt.name + '; ') : ''}
                                            </Typography>
                                        </Typography>
                                        {/* <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                Date d'inscription :{' '}
                                                <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                    {user?.createdAt ? dayjs(user.createdAt).format('DD/MM/YYYY') : ''}
                                                </Typography>
                                            </Typography> */}
                                        {/* <Button 
                                            // variant="contained" 
                                            // color="primary" 
                                            style={btRetourStyle}
                                            onClick={handleNavigatePresta} 
                                            className="mt-4"
                                        >
                                            🡄Retour
                                        </Button> */}
                                    </div>
                                )}
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Assujetie à la TVA :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user?.idEntreprise ? 
                                                    (user?.idEntreprise?.isTva ? 
                                                        (user?.idEntreprise?.isTva == true ? (
                                                            <input
                                                                type="checkbox"
                                                                checked={true}
                                                                disabled // Rendre le checkbox non éditable
                                                                />
                                                        ):(
                                                            <input
                                                                type="checkbox"
                                                                checked={false}
                                                                disabled // Rendre le checkbox non éditable
                                                                />
                                                        )) 
                                                    : (
                                                        <input
                                                                type="checkbox"
                                                                checked={false}
                                                                disabled // Rendre le checkbox non éditable
                                                                />
                                                    ))  
                                                : ''}
                                            </Typography>
                                        </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {user && user.idEntreprise && (
                    <Grid item xs={12}>
                        <Card className="w-full" sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant="h6" component="div" fontWeight={'bold'}>
                                    Info Entreprise
                                </Typography>
                                <Grid container spacing={2}>
                                {!user?.statusUser.toLowerCase().includes('presta') && (
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Nom de votre entreprise :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user.idEntreprise.nomEntreprise}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Nom du gérant :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {'Prénom 01'}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Adresse :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user.idEntreprise.adressEntreprise?.address}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Ville :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user.idEntreprise.citeEP}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Téléphone :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user.idEntreprise.tel}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    )}
                                    <Grid item xs={6}>
                                    {!user?.statusUser.toLowerCase().includes('presta') && (
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Domaine :{' '}
                                            {user?.idEntreprise?.domaineEntreprise && user?.idEntreprise?.domaineEntreprise?.name && (
                                                // user.idEntreprise.domaineEntreprise.map((elt: any) => (
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {user?.idEntreprise?.domaineEntreprise?.name}
                                                    </Typography>
                                                // )
                                                )
                                                }
                                        </Typography>
                                    )}
                                    {!user?.statusUser.toLowerCase().includes('presta') && (
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Poste au sein de l'entreprise :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user?.posteEntreprise ? user?.posteEntreprise : ""}
                                            </Typography>
                                        </Typography>
                                    )}
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Siret de votre entreprise :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user.idEntreprise.siretEntreprise}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            N°TVA intracommunautaire :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user.idEntreprise.tva}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                            Code APE :{' '}
                                            <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                                {user.idEntreprise.apeCode}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
            {!isSousCompte && !isPrestataire && !isCreateurNotMulti && (
                <div style={{ margin: 10 }}>
                    <Typography variant="h6" fontWeight={'bold'}>
                        Membre de l'équipe
                    </Typography>
                    
                    <TableContainer component="div" sx={{ maxHeight: 440 }}>
                        <Table
                            stickyHeader
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: 'none'
                                }
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeader>Nom et prénom</StyledTableHeader>
                                    <StyledTableHeader>Ville</StyledTableHeader>
                                    <StyledTableHeader>Téléphone</StyledTableHeader>
                                    {/* <StyledTableHeader>Type de Mission</StyledTableHeader> */}
                                    <StyledTableHeader>N° siret</StyledTableHeader>
                                    <StyledTableHeader>Email</StyledTableHeader>
                                    <StyledTableHeader>Code APE</StyledTableHeader>
                                    <StyledTableHeader></StyledTableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {user && user.idEntreprise && user?.idEntreprise.member && user?.idEntreprise.member.map((membre:any) =>
                                    membre._id !== user?._id && (
                                    <TableRow key={membre._id}>
                                    <TableCell>{membre.lastname} {membre.firstname}</TableCell>
                                    <TableCell>{user.idEntreprise.citeEP ? user.idEntreprise.citeEP : " "}</TableCell>
                                    <TableCell>{membre.numero}</TableCell>
                                    <TableCell>{user.idEntreprise.siretEntreprise ? user.idEntreprise.siretEntreprise: " "}</TableCell>
                                    <TableCell>{membre.email}</TableCell>
                                    <TableCell>{user.idEntreprise.apeCode ? user.idEntreprise.apeCode : " "}</TableCell>
                                    <TableCell>
                                        {/* Vous pouvez ajouter des actions ici, comme un bouton pour modifier ou supprimer */}
                                    </TableCell>
                                </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* <Box sx={{ display: 'flex', mt: 5 }}>
                        <Grid container alignItems="center" justifyContent="end" gap={1}>
                            <Stack spacing={2}>
                                <Pagination
                                    // showFirstButton
                                    // showLastButton
                                    count={10}
                                    // page={1}
                                    onChange={() => {}}
                                    color="primary"
                                    shape="rounded" 
                                />
                            </Stack>

                            <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                                <Select
                                    value={itemParPage}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={'5'}>5 / page</MenuItem>
                                    <MenuItem value={'10'}>10 / page</MenuItem>
                                    <MenuItem value={'25'}>25 / page</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Box> */}
                    {/* <ModaleValidationComponent
                        openModale={open}
                        setOpen={setOpen}
                        message={`Voulez-vous supprimer ce sous-compte`}
                        handleValidate={() => deleteSousCompte(selectedItem)}
                    /> */}
                </div>
            )}
            {isPrestataire &&(
            <Button 
                // variant="contained" 
                // color="primary" 
                style={btRetourStyle}
                onClick={handleNavigatePresta} 
                className="mt-4"
                >
                🡄Retour
            </Button>
            )}
            {isCreateurNotMulti &&(
            <Button 
                // variant="contained" 
                // color="primary" 
                style={btRetourStyle}
                onClick={handleNavigateCrea} 
                className="mt-4"
                >
                🡄Retour
            </Button>
            )}
            {!isCreateurNotMulti && !isPrestataire &&(
            <Button 
                // variant="contained" 
                // color="primary" 
                style={btRetourStyle}
                onClick={handleNavigateMultiCrea} 
                className="mt-4"
                >
                🡄Retour
            </Button>
            )}
        </>
    )
}

export default UtilisateurDetailsComponent
