import { ReactNode, useEffect, useState } from 'react'
import { IDomaines, User } from '../../../pages/Utilisateur/interfaces/utilisateur.interface'
import { FormControl, Grid, Input, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import domaineServices from '../../../pages/Domaine/services/domaine.service'
import { ApiResponse } from '../../interface/ApiResponse.interface'
import { DomaineResponse } from '../../../pages/Domaine/interfaces/domaine.interface'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { toast } from 'react-toastify'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneIcon from '@mui/icons-material/Phone'
import MoneyIcon from '@mui/icons-material/Money'
import NumbersIcon from '@mui/icons-material/Numbers'
import PercentIcon from '@mui/icons-material/Percent'
import { Checkbox, FormControlLabel, Button } from '@mui/material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BadgeIcon from '@mui/icons-material/Badge';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import userServices from '../../../pages/Utilisateur/services/utilisateur.service'

export default function FilterUserCreateurMulti(props: FilterUserProps) {
    const [domaineOption, setDomaineOption] = useState<IDomaines[]>([])
    const [loading, setLoading] = useState(false)
    const { onChangeUser, user } = props
    const [startDate, setStartDate] = useState<Dayjs | null>(null)
    const [endDate, setEndDate] = useState<Dayjs | null>(null)
    const [needTva, setNeedTVA] = useState<boolean>(false)
    const [statusUserOption, setStatusUserOption] = useState<any[]>([])
    const [shouldBePayeur, setShouldBePayeur] = useState<boolean>(false)
    const [chefDEquipe, setChetDEquipe] = useState<boolean>(false)
    // const roleId = props.roleId
    // const [villesOption, setVillesOption] = useState<any[]>([])
    // const { onChangeVille , ville } = props
    // const [matricule, setMatricule] = useState<string>("")

    // console.log(props.isTva)

    useEffect(() => {
        setLoading(true)
        domaineServices
            .get()
            .then((res: ApiResponse<DomaineResponse>) => setDomaineOption(res.data.domaines))
            .catch(console.log)
            .finally(() => setLoading(false))
        // eslint-disable-next-line

        userServices
            .getAllStatus()
            .then(
                (res: any) =>{
                setStatusUserOption(res.data.data)
                console.log("statusUser: ", res.data.data)
                }
            )
            .catch(console.log)
    }, [])
    useEffect(() =>{
        //Props pour qu ,grace a "interface" on peut recuperer utiliser valeur
        props.onChangeNeedTva(needTva)
    },[needTva])
    useEffect(() =>{
        //Props pour que ,grace a "interface" on peut recuperer utiliser valeur
        props.onChangeShouldBePayeur(shouldBePayeur)
    },[shouldBePayeur])
    useEffect(() =>{
        //Props pour que ,grace a "interface" on peut recuperer utiliser valeur
        props.onChangeChefDEquipe(chefDEquipe)
    },[chefDEquipe])
    // toast.error('Veuillez vérifier votre date de fin')

    const reinitChefDEquipe = () =>{
        if(chefDEquipe == true){
            setChetDEquipe(!chefDEquipe)
        }
    }
    const reinitVille = () =>{
        onChange('ville', "")
        props.onChangeVille("")
    }
    const reinitFilter = () =>{
        // props.onChangeDomaine()
        setNeedTVA(false)
        props.onChangeNiveau("")
        reinitVille()
        onChange('_id', "")
        props.onChange_id("")
        onChange('name', "")
        props.onChangeName("")
        setShouldBePayeur(false)
        // setChetDEquipe(false)
        reinitChefDEquipe()
    }

    const onChange = (name: string, value: any) => {
        if (name === 'startDate' && value && !endDate) {
            onChangeUser &&
                onChangeUser({
                    ...user,
                    startDate: value,
                    endDate: dayjs()
                })
            setStartDate(value)
            setEndDate(dayjs())
        } else if (name === 'endDate' && value && startDate && dayjs(value).isBefore(dayjs(startDate))) {
            setEndDate(dayjs())
            toast.error('Veuillez vérifier votre date de fin')
        } 
        else if (name === 'tva') {
            setNeedTVA(!needTva)
            // props.onChangeNeedTva(needTva)
            console.log("Need TVA: "+ needTva)
        } 
        else if (name === 'shouldBePayeur') {
            setShouldBePayeur(!shouldBePayeur)
            // props.onChangeNeedTva(needTva)
            console.log("Should be payeur: "+ shouldBePayeur)
        } 
        else if (name === 'chefDEquipe') {
            setChetDEquipe(!chefDEquipe)
            // props.onChangeNeedTva(needTva)
            console.log("chef d'equipe: "+ chefDEquipe)
        } 
        // else if (name === 'citeEP') {
        //     onChangeVille &&
        //     onChangeVille({
        //             ...ville,
        //             [name]: value
        //         })
        // } 
        else {
            // Logique pour le champ de texte "Matricule"
            if (name === '_id') {
                // Si la valeur commence par "HWK" ou "hwk", on ignore cette partie
                if (value.toUpperCase().startsWith('HWK')) {
                    value = value.substring(3);
                }
            }
            
            onChangeUser &&
                onChangeUser({
                    ...user,
                    [name]: value
                })
        }
    }

    return (
        <div className="flex flex-col">
            {loading ? (
                <Skeleton variant="rectangular" width={440} className="py-4" />
            ) : (
                <Grid container spacing={2} padding={5} direction={'row'}>
                    {/* <Grid item xs={12} lg={12}> */}
                    <Grid item>
                        <FormControl variant="outlined" sx={{ minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-standard-label">Domaine</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={props.domaine?._id}
                                onChange={e => props.onChangeDomaine(domaineOption.find(domaine => domaine._id === e.target.value))}
                                label="Domaine"
                            >
                                <MenuItem value={''}>
                                    <em>Aucun</em>
                                </MenuItem>
                                {domaineOption.map(domaine => (
                                    <MenuItem value={domaine._id}>{domaine.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" sx={{ minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-standard-label">Niveau</InputLabel>
                            <Select
                                // labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={props?.niveau && props.niveau}
                                // onChange={e => onChange('ville', e.target.value)}
                                onChange={e => props.onChangeNiveau(statusUserOption.find((niveau:any) => niveau === e.target.value))}
                                label="Niveau"
                            >
                                <MenuItem value={''}>
                                    <em>Aucun</em>
                                </MenuItem>
                                {statusUserOption && statusUserOption.map((niveau:any) => (
                                    <MenuItem value={niveau}>{niveau}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Ville"
                            value={user?.idEntreprise?.citeEP && user?.idEntreprise?.citeEP}
                            variant="outlined"
                            onChange={e => onChange('ville', e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LocationCityIcon />
                                    </InputAdornment>
                                )
                            }}
                            // sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Nom du créateur"
                            value={user?.name}
                            variant="outlined"
                            onChange={e => onChange('name', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Nom du chef d'équipe"
                            value={user?.idEntreprise?.nomGerant}
                            variant="outlined"
                            onChange={e => onChange('nomGerant', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SupervisorAccountIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Nom du payeur"
                            // value={user?.name}
                            variant="outlined"
                            // onChange={e => onChange('name', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {/* <AccountCircleIcon /> */}
                                        <MonetizationOnIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    
                    <Grid item>
                        <TextField
                            label="Matricule"
                            value={user?._id}
                            variant="outlined"
                            onChange={e => onChange('_id', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <BadgeIcon />
                                    </InputAdornment>
                                )
                            }}
                            // sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            label="TVA"
                            value={needTva}
                            // variant="outlined"
                            // onChange={e => onChange('tva', e.currentTarget.value)}
                            control={
                                <Checkbox
                                    checked={needTva}
                                    onChange={e => onChange('tva',e.target.checked)}
                                />
                            }
                            // sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            label="Chef d'équipe"
                            value={chefDEquipe}
                            // variant="outlined"
                            // onChange={e => onChange('tva', e.currentTarget.value)}
                            control={
                                <Checkbox
                                    checked={chefDEquipe}
                                    onChange={e => onChange('chefDEquipe',e.target.checked)}
                                />
                            }
                            // sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            label="Payeur"
                            value={shouldBePayeur}
                            // variant="outlined"
                            // onChange={e => onChange('tva', e.currentTarget.value)}
                            control={
                                <Checkbox
                                    checked={shouldBePayeur}
                                    onChange={e => onChange('shouldBePayeur',e.target.checked)}
                                />
                            }
                            // sx={{ width: 250 }}
                        />
                    </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={reinitFilter} 
                        >
                            Réinitialiser les filtres
                        </Button>
                    </Grid>
                </Grid>
            )}
            {props.children}
        </div>
    )
}

export interface FilterUserProps {
    children: ReactNode
    domaine?: IDomaines
    onChangeDomaine: (domaine?: IDomaines) => void
    user?: any
    onChangeUser?: (user: any) => void
    name?: string
    onChangeName: (name: string) => void
    nomGerant?: string
    onChangeNomGerant: (nomGerant: string) => void
    // isTva?: boolean
    needTva?: boolean
    onChangeNeedTva:(needTva: boolean) => void
    _id?: string
    onChange_id:(_id: string) => void
    ville?:string
    onChangeVille: (ville: string) => void
    niveau?: string
    onChangeNiveau: (niveau?: string) => void
    shouldBePayeur?: boolean
    onChangeShouldBePayeur:(shouldBePayeur: boolean) => void
    chefDEquipe?: boolean
    onChangeChefDEquipe:(chefDEquipe: boolean) => void
    // roleId:string
}
