import httpCommon from '../../../shared/services/http-common'
import { Domaine } from '../../Domaine/interfaces/domaine.interface'
import { CreateAdminDto, User, UserRole } from '../interfaces/utilisateur.interface'

class UserServices {
    getAll(page: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users?page=${page}`)
    }
    getAllUsers() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/listeUsers`)
    }

    getById(userId: User['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/one/${userId}`)
    }
    getByEmail(userEmail: User['email']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/${userEmail}`)
    }
    getByCreateurRoleMulti(page: number, roleId: UserRole['_id'], limit: number, name?: string,nomGerant?:string, domaineId?: Domaine['_id'], needTva?: boolean, _id?: string,ville?:string,niveau?:string,shouldBePayeur?:boolean,chefDEquipe?:boolean, query?: any): Promise<any> {
        let url: string[] = []
        if (name) url.push(`name=${name}`)
        if (nomGerant) url.push(`nomGerant=${nomGerant}`)
        if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        if(needTva) url.push(`needTva=${needTva}`)
        if(_id) url.push(`_id=${_id}`)
        if(ville) url.push(`ville=${ville}`)
        if(niveau) url.push(`statusUser=${niveau}`)
        if(shouldBePayeur) url.push(`shouldBePayer=${shouldBePayeur}`)
        // shouldBePayer (pas shouldBePayeur)
        if(chefDEquipe) url.push(`chefDEquipe=${chefDEquipe}`)
        if (query) {
            for (const key of Object.keys(query)) {
                if (query[key] && query[key] !== '') {
                    url.push(`${key}=${query[key]}`)
                }
            }
        }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/by-role/${roleId}/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    getByCreateurRole(page: number, roleId: UserRole['_id'], limit: number, name?: string, domaineId?: Domaine['_id'], needTva?: boolean, _id?: string,ville?:string,niveau?:string,shouldBePayeur?:boolean,chefDEquipe?:boolean, query?: any): Promise<any> {
        let url: string[] = []
        if (name) url.push(`name=${name}`)
        if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        if(needTva) url.push(`needTva=${needTva}`)
        if(_id) url.push(`_id=${_id}`)
        if(ville) url.push(`ville=${ville}`)
        if(niveau) url.push(`statusUser=${niveau}`)
        if(shouldBePayeur) url.push(`shouldBePayer=${shouldBePayeur}`)
        // shouldBePayer (pas shouldBePayeur)
        if(chefDEquipe) url.push(`chefDEquipe=${chefDEquipe}`)
        if (query) {
            for (const key of Object.keys(query)) {
                if (query[key] && query[key] !== '') {
                    url.push(`${key}=${query[key]}`)
                }
            }
        }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/by-role/${roleId}/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    getByRole(page: number, roleId: UserRole['_id'], limit: number, name?: string, domaineId?: Domaine['_id'], needTva?: boolean, _id?: string,ville?:string,niveau?:string,shouldBePayeur?:boolean, query?: any): Promise<any> {
        let url: string[] = []
        if (name) url.push(`name=${name}`)
        if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        if(needTva) url.push(`needTva=${needTva}`)
        if(_id) url.push(`_id=${_id}`)
        if(ville) url.push(`ville=${ville}`)
        if(niveau) url.push(`statusUser=${niveau}`)
        if(shouldBePayeur) url.push(`shouldBePayer=${shouldBePayeur}`)
        // shouldBePayer (pas shouldBePayeur)
        if (query) {
            for (const key of Object.keys(query)) {
                if (query[key] && query[key] !== '') {
                    url.push(`${key}=${query[key]}`)
                }
            }
        }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/by-role/${roleId}/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    getUniqueCiteEPsOuVilles() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/getvilles`)
      }

    verifierConfirmationSuppression(id: User['_id'],email: User['email'], password: User['password']) {
        return httpCommon.post(
            `${process.env.REACT_APP_API_ROOT}/users/verification_confirmation_suppression`,{
                _id: id,
                email: email,
                password: password
              }
        )
      }

    getASuppr(page: number, limit: number,email: string): Promise<any> {
        let url: string[] = []
        // if (name) url.push(`name=${name}`)
        // if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        // if(needTva) url.push(`needTva=${needTva}`)
        // if(_id) url.push(`_id=${_id}`)
        if (email) url.push(`email=${email}`)
        // if (query) {
        //     for (const key of Object.keys(query)) {
        //         if (query[key] && query[key] !== '') {
        //             url.push(`${key}=${query[key]}`)
        //         }
        //     }
        // }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/find/a_suppr/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    deleteAUser(userId: User['_id']) {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/users/${userId}`)
    }

    getRoles(): Promise<any> {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/roles`)
    }

    createAdmin(params: CreateAdminDto): Promise<any> {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/users/create-admin`, params)
    }

    getAllAdmin(): Promise<any> {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/getall-admin`)
    }
    updatePresta(data: any){
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/users/update`, data)
    }
    updateAdminEmail(_id: User['_id'], email?: User['email']){
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/users/updateAdminEmail`, { _id, email })
    }
    getAllStatusByRole(roleId: UserRole['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/statusUserByRole/${roleId}`)
    }
    getAllStatus() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/statusUser`)
    }
}

const userServices = new UserServices()

export default userServices
